.contact-img {
  display: inline-block;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-size: 32px 35px;
  background-position: center center;
  vertical-align: middle;
  line-height: 32px;
  box-shadow: inset 0 0 1px #999, inset 0 0 10px rgba(0, 0, 0, 0.2);
  margin-left: 5px;
}

.k-rating-item.k-selected {
  color: #ffa600;
}

.selected-row {
  background-color: rgb(69, 99, 231);
  color: white;
}

.unselected-row {
  background-color: rgb(235, 235, 235);
  color: white;
}

.no-border-sparkline svg > g > path:first-child {
  fill: none !important;
}

.no-border-sparkline {
  width: 100%;
}

.no-border-sparkline .k-sparkline {
  width: 100% !important;
}

.k-badge-container {
  margin-left: 30px;
  margin-bottom: 5px;
}

.custom-calendar .k-calendar-header .k-button.k-bare {
  color: rgba(66, 139, 202, 1) !important;
}

.custom-calendar .k-calendar-table .k-calendar-td .k-link {
  color: rgb(15, 15, 15) !important;
}

.custom-calendar .k-calendar-table .k-calendar-td.k-selected .k-link {
  color: rgba(66, 139, 202, 1) !important;
}

.custom-calendar .k-calendar-table td.k-selected .k-link {
  background-color: rgba(
    66,
    139,
    202,
    0.5
  ) !important; /* Цвет фона для выделенной ячейки */
  color: rgba(66, 139, 202, 1) !important; /* Цвет текста в выделенной ячейке */
}

/* Цвет текста сегодняшней даты */
.custom-calendar .k-calendar-table td.k-today .k-link {
  color: rgba(
    66,
    139,
    202,
    1
  ) !important; /* Цвет текста для сегодняшней даты */
  border-color: rgba(
    66,
    139,
    202,
    1
  ) !important; /* Цвет границы для сегодняшней даты */
}

.custom-calendar .k-button-flat-primary {
  color: rgba(66, 139, 202, 1) !important;
  font-weight: bold !important;
  font-size: medium;
}


.custom-pager .k-pager .k-button-text {
  color: #428bca  !important;
}

.custom-pager .k-pager .k-button-flat-primary {
  background-color: rgba(66, 139, 202, 0) !important;
  border-color: rgba(66, 139, 202, 0)  !important;
  color:rgba(66, 139, 202, 0) !important;
}

.custom-pager .k-pager .k-button-flat-primary.k-selected{
  background-color: rgba(66, 139, 202, 1) !important;
  color:rgba(66, 139, 202, 0) !important;
  border-color: rgba(66, 139, 202, 1)  !important;
}

.custom-pager .k-pager .k-button-flat-primary.k-selected .k-button-text {
  color: #ffffff  !important;
}

.button {
  display: flex;
  align-items: center;
  padding: 8px 15px;
  background-color: #ffffff;
  color: #000000;
  border: 1px solid #cccccc;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  margin-top: 5px;
  margin-right: 5px;
  transition: background-color 0.3s, color 0.3s;
}

.button:hover {
  background-color: #428bca;
  color: #ffffff;
}

.k-list-container .k-list-item.k-selected {
  background-color: #357ebd !important; 
  color: #ffffff !important;
}

.k-list-container .k-list-item:hover {
  background-color: #428bca38 !important;
  color: #ffffff !important;
}

.grid-cell-edit-icon {
  display: none;
  margin-left: 5px;
  cursor: pointer;
}

.grid-cell:hover .grid-cell-edit-icon {
  display: inline;
}